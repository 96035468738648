import React from 'react';
import Shake from 'react-reveal/Shake';
import about_1 from '../../images/glow-eggs.gif';
import about_2 from '../../images/about2.gif';
import about_3 from '../../images/coin-dance.gif';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import cornerStars from '../../images/corner-stars.svg';
import cornerStars2 from '../../images/corner-stars2.svg';
import cornerStarsInd1 from '../../images/corner-stars-ind-1.svg';
import cornerStarsInd2 from '../../images/corner-stars-ind-2.svg';
import star from '../../images/star.svg';

const About: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <section id="about" className="w-full flex-col">
      <div className="flex flex-col justify-center items-center">
        <div className="w-5/6 md:w-4/6">
          <h2 className="text-3xl text-pink-400 font-medium mt-20 mb-2 font-super uppercase">
            {t('About.heading', 'Play 2 earn')}
          </h2>
          <div className="flex flex-col md:flex-row items-center space-y-8 md:space-x-8 py-6">
            <Shake>
              <div className="relative w-full md:w-1/3 xl:w-1/4">
                <img className="absolute corner-stars2 w-20" src={cornerStarsInd1} alt="" />
                <img
                  className="rounded-xl border-4 border-white"
                  src={about_1}
                  alt="Monsta Party"
                />
              </div>
            </Shake>
            <div className="w-full md:w-2/3 mt-4 md:mt-0">
              <h3 className="mb-4 font-super text-xl">
                {t('About.description1.part0', 'It Starts With An Egg')}
              </h3>
              <span className="flex items-center mb-4">
                <img className="w-6 mr-2" src={star} />
                <p className="text-lg">
                  {t('About.description1.part1', 'Initially everyone mints a Gen-0 Egg.')}
                </p>
              </span>
              <span className="flex items-center mb-4">
                <img className="w-6 mr-2" src={star} />
                <p className="text-lg">
                  {t(
                    'About.description1.part2',
                    'Soon your Gen-0 Egg will hatch into a Gen-0 Party Monsta.',
                  )}
                </p>
              </span>
              <span className="flex items-center mb-4">
                <img className="w-6 mr-2" src={star} />
                <p className="text-lg">
                  {t(
                    'About.description1.part3',
                    'Once hatched, your Party Monsta starts gaining daily Party Experience Points (PXP).',
                  )}
                </p>
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row-reverse items-center space-y-8 gap-x-8  py-6">
            <Shake>
              <div className="relative w-full md:w-1/3 xl:w-1/4">
                <img className="absolute corner-stars w-20" src={cornerStarsInd2} alt="" />
                <img
                  className="rounded-xl border-4 border-white"
                  src={about_2}
                  alt="Monsta Party"
                />
              </div>
            </Shake>
            <div className="w-full md:w-2/3 mt-4 md:mt-0">
              <h3 className="mb-4 font-super text-xl">
                {t('About.description2.part0', 'FEED TO EARN PXP FASTER')}
              </h3>
              <span className="flex items-center mb-4">
                <img className="w-6 mr-2" src={star} />
                <p className="text-lg">
                  {t(
                    'About.description2.part1',
                    'Feeding is available at 12 random times throughout the day.',
                  )}
                </p>
              </span>
              <span className="flex items-center mb-4">
                <img className="w-6 mr-2" src={star} />
                <p className="text-lg">
                  {t('About.description2.part2', 'Feeding increases PXP faster.')}
                </p>
              </span>
              <span className="flex items-center mb-4">
                <img className="w-6 mr-2" src={star} />
                <p className="text-lg">
                  {t(
                    'About.description2.part3',
                    'Earn bonus PXP by completing daily tasks and by playing in the Arcade.',
                  )}
                </p>
              </span>
              <span className="flex items-center mb-4">
                <img className="w-6 mr-2" src={star} />
                <p className="text-lg">
                  {t(
                    'About.description2.part4',
                    'When you reach 10,000 PXP you give birth to a Gen-1 Party Monsta.',
                  )}
                </p>
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center space-y-8 md:space-x-8 py-6">
            <Shake>
              <div className="relative w-full md:w-1/3 xl:w-1/4">
                <img className="absolute corner-stars2 w-20" src={cornerStarsInd1} alt="" />
                <img
                  className="rounded-xl border-4 border-white"
                  src={about_3}
                  alt="Monsta Party"
                />
              </div>
            </Shake>
            <div className="w-full md:w-2/3 mt-4 md:mt-0">
              <h3 className="mb-4 font-super text-xl">
                {t('About.description3.part0', 'PLAYER REWARDS')}
              </h3>
              <span className="flex items-center mb-4">
                <img className="w-6 mr-2" src={star} />
                <p className="text-lg">
                  {t('About.description3.part1', 'All NFT holders earn $MONSTA dividends.')}
                </p>
              </span>
              <span className="flex items-center mb-4">
                <img className="w-6 mr-2" src={star} />
                <p className="text-lg">
                  {t('About.description3.part2', 'NFTs with higher PXP get higher rewards!')}
                </p>
              </span>
              <span className="flex items-center mb-4">
                <img className="w-6 mr-2" src={star} />
                <p className="text-lg">
                  {t(
                    'About.description3.part3',
                    'Certain PXP levels will allow you to claim some free, real world assets',
                  )}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-16">
        <img src={rarity} alt="Monsta Party Rarity" className="w-full" />
      </div> */}
    </section>
  );
};

export default About;
